import axios from 'axios'

const SET_TRAINERS_DATA = 'trainer/SET_TRAINERS_DATA'
const TRAINERS_DATA_START_FETCHING = 'trainer/TRAINERS_DATA_START_FETCHING'
const TRAINERS_DATA_STOP_FETCHING = 'trainer/TRAINERS_DATA_STOP_FETCHING'

const setTrainers = (trainersData) => ({
  type: SET_TRAINERS_DATA,
  payload: { trainersData },
})

const setTrainersIsFetching = () => ({
  type: TRAINERS_DATA_START_FETCHING,
})

const unsetTrainersIsFetching = () => ({
  type: TRAINERS_DATA_STOP_FETCHING,
})

export const getTrainersData = () => (dispatch, getState) => {
  const questions = getState().poll.pollData.questions

  if (!questions) {
    dispatch(setTrainers([]))
    return
  }

  const trainersUids = (
    questions
      .map((question) => question.trainer)
      .filter((trainerUid) => trainerUid)
      .filter((el, i, arr) => arr.indexOf(el) === i)
  )

  if (trainersUids.length === 0) {
    dispatch(setTrainers([]))
    return
  }

  dispatch(setTrainersIsFetching())
  const promises = trainersUids.map((trainerUid) => (
    axios.get(`${window._env_.REACT_APP_API_URL}/actions/trainers/${trainerUid}`)
      .then(({ data }) => ({
        trainerUid: data._id,
        ...data,
      }))
  ))

  return Promise.all(promises)
    .then((data) => dispatch(setTrainers(data)))
    .finally(() => dispatch(unsetTrainersIsFetching()))
}

const initialState = {
  trainersData: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TRAINERS_DATA_START_FETCHING:
      return {
        ...state,
        trainersAreFetching: true,
      }
    case TRAINERS_DATA_STOP_FETCHING:
      return {
        ...state,
        trainersAreFetching: false,
      }
    case SET_TRAINERS_DATA:
      return {
        ...state,
        trainersData: action.payload.trainersData,
      }
    default:
      return state
  }
}