import React from 'react'

import TextField from '@material-ui/core/TextField'

import t from '../../../../translations'
import { Typography } from '@material-ui/core'

const style = { width: '100%' }

const QuestionText = ({ question, setAnswer, answers }) => {
  const pickedAnswer = answers && answers[question.questionId]

  const pickAnswerHandler = (event) => setAnswer(question.questionId, event.target.value)

  return (
    <div className={'QuestionText'}>
      <br />
      <Typography variant={'h6'} style={{ fontWeight: 700 }}>{question.caption}</Typography>
      <Typography paragraph={true} variant={'body1'}>{question.description}</Typography>
      <TextField
        onChange={pickAnswerHandler}
        value={pickedAnswer}
        placeholder={t.pollView.answerText}
        style={style}
      />
      <br />
    </div >
  )
}

export default QuestionText