/* eslint-disable max-len */
export default {
  pollView: {
    fetchingPoll: 'Ładowanie ankiety...',
    okBtnTxtAnswer: 'WSZYSTKO OK (uczestnik kliknał przycisk ;))',
    okBtn: 'Wszystko jest okej! ZAPISZ! :)',
    okBtnCaption: 'Jeśli uważasz, że kurs w 101% spełnia Twoje oczekiwania, nie masz żadnych zastrzeżeń, kliknij ten przycisk! Spowoduje on zapisanie ankiety maksymalnymi ocenami we wszystkich pytaniach ze skalą ocen. Korzystaj odpowiedzialnie!  ;)',
    save: 'Zapisz',
    pollNotFound: 'Nie znaleziono ankiety lub ankieta została zarchiwizowana i nie można jej już uzupełnić!',
    answerText: 'Treść odpowiedzi',
    notPresent: 'Nie było mnie na zajęciach',
    fillRequired: 'Wypełnij wymagane pola (pytania z zakresem 1-5 lub 0-10)',
    fillRequiredReasons: 'Wpisano niskie oceny nie podając uzasadnienia!\n\nUzasadnij swoją ocenę, wypełniając przynajmniej jedno pole tekstowe, dla trenerów:\n',
    pollIsSaved: 'Poprawnie zapisano ankietę. Dziękujemy!',
    pollIsAlreadyFilled: 'Wygląda na to, że wypełniałeś/aś już tę ankietę!',
    savingPoll: 'Zapisywanie ankiety...',
    error: 'Wystąpił błąd podczas zapisywania ankiety! Spróbuj jeszcze raz, a jeśli się nie uda poinformuj trenera!',
  },
  loadingMessages: {
    fetchingResources: 'Ładuje zasoby...',
    fetchingAnswers: 'Ładuję odpowiedzi...',
    loadingData: 'Wczytuje dane...',
  },
}
