import React from 'react'

import Container from '../../../components/Container'
import Button from '@material-ui/core/Button'

import t from '../../../translations'

const SavePollButton = (props) => (
  <Container>
    <Button
      variant={'contained'}
      color={'primary'}
      onClick={props.onClick}
      fullWidth={true}
    >
      {t.pollView.save}
    </Button>
  </Container>
)

export default SavePollButton