import axios from 'axios'

import { pollIsAlreadyFilledLocalStoragePrefix } from '../options'

import { getTrainersData } from './trainer'
import { getCoursesData } from './courses'

export const SET_POLL_DATA = 'poll/SET_POLL_DATA'
export const SET_NO_POLL_DATA = 'poll/SET_NO_POLL_DATA'
export const POLL_DATA_START_FETCHING = 'poll/POLL_DATA_START_FETCHING'
export const POLL_DATA_STOP_FETCHING = 'poll/POLL_DATA_STOP_FETCHING'
export const POLL_IS_ALREADY_FILLED = 'poll/POLL_IS_ALREADY_FILLED'

const setPool = (pollData) => ({
  type: SET_POLL_DATA,
  payload: { pollData },
})

const setNoPool = () => ({ type: SET_NO_POLL_DATA })

const setPoolIsFetching = () => ({ type: POLL_DATA_START_FETCHING })

const unsetPoolIsFetching = () => ({ type: POLL_DATA_STOP_FETCHING })

const setPoolIsAlreadyFilled = () => ({ type: POLL_IS_ALREADY_FILLED })

export const getPollData = (pollUid) => (dispatch, getState) => {
  const wasPollAlreadyFilled = window.localStorage.getItem(`${pollIsAlreadyFilledLocalStoragePrefix}${pollUid}`)

  if (!pollUid) {
    dispatch(setNoPool())
    dispatch(unsetPoolIsFetching())
    return Promise.reject()
  }

  if (wasPollAlreadyFilled) {
    dispatch(setPoolIsAlreadyFilled())
    return Promise.reject()
  }

  dispatch(setPoolIsFetching())

  return axios.get(`${window._env_.REACT_APP_API_URL}/actions/polls/${pollUid}?populate[0]=template`)
    .then(({ data }) => {
      dispatch(setPool({
        ...data,
        pollUid: data._id,
      }))

      dispatch(getCoursesData())
      dispatch(getTrainersData())
    })
    .catch((error) => {
      dispatch(setNoPool())
      return Promise.reject(error)
    })
    .finally(() => dispatch(unsetPoolIsFetching()))
}

const initialState = {
  pollData: {},
  pollIsFetching: true,
  pollIsAlreadyFilled: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case POLL_IS_ALREADY_FILLED:
      return {
        ...state,
        pollIsAlreadyFilled: true,
      }
    case POLL_DATA_START_FETCHING:
      return {
        ...state,
        pollIsFetching: true,
      }
    case POLL_DATA_STOP_FETCHING:
      return {
        ...state,
        pollIsFetching: false,
      }
    case SET_NO_POLL_DATA:
      return {
        ...state,
        pollData: null,
      }
    case SET_POLL_DATA:
      return {
        ...state,
        pollData: action.payload.pollData,
      }
    default:
      return state
  }
}