import React from 'react'

import Container from '../Container'

import t from '../../translations'
import { Typography } from '@material-ui/core'

const styles = {
  subjectHeader: {
    fontWeight: 700,
    textAlign: 'center',
  },
}

const TrainerAndSubjectHeader = ({ trainer, subject, showHeaderWithoutTrainer }) => (
  trainer || showHeaderWithoutTrainer ?
    <Container
      colored={true}
    >
      <Typography
        variant={'h6'}
        style={styles.subjectHeader}
      >
        {
          trainer ?
            `${trainer.firstName} ${trainer.lastName} ${subject !== 'undefined' ? `- ${subject}` : ''}`
            :
            `${t.answers.print.answersHeader} ${t.answers.print.none}`
        }
      </Typography>
    </Container>
    :
    null
)

export default TrainerAndSubjectHeader