import React from 'react'
import LoadingIndicator from '../../../components/LoadingIndicator'

import t from '../../../translations'

const FetchingPoll = () => (
  <LoadingIndicator message={t.pollView.fetchingPoll} />
)

export default FetchingPoll
