import React from 'react'

import Container from '../../../components/Container'

import t from '../../../translations'
import { Typography } from '@material-ui/core'

const PollNotFound = () => (
  <Container>
    <Typography variant={'body2'}>
      {t.pollView.pollNotFound}
    </Typography>
  </Container>
)

export default PollNotFound
