import React from 'react'

import TrainerAndSubjectHeader from '../../../components/TrainerAndSubjectHeader'
import Container from '../../../components/Container'
import Question from './Question/Question'

const QuestionsPerTrainerAndSubject = (props) => (
  <div>
    <TrainerAndSubjectHeader
      trainer={props.trainer}
      subject={props.subject}
    />
    <Container>
      {
        props.questions.map((question, i, arr) => (
          <Question
            key={question.questionId}
            question={question}
            isNotLastQuestion={i !== arr.length - 1}
            setAnswer={props.setAnswer}
            answers={props.answers}
          />
        ))
      }
    </Container>
  </div>
)

export default QuestionsPerTrainerAndSubject