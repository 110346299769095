import React from 'react'
import _ from 'lodash'

import { Button, Typography } from '@material-ui/core'

import Container from '../../../components/Container'

import t from '../../../translations'

const PollSaved = ({ pollData }) => {
  const reviewLink = _.get(pollData, 'template.reviewLink')
  const reviewLabel = _.get(pollData, 'template.reviewLabel')

  return (
    <Container>
      <div style={{ textAlign: 'center' }}>
        <Typography variant={'h5'}>
          {t.pollView.pollIsSaved}
        </Typography>
        {
          reviewLink ?
            <>
              <br />
              <Button
                href={reviewLink}
                variant={'contained'}
                color={'primary'}
              >
                {reviewLabel}
              </Button>
            </>
            :
            null
        }
      </div>
    </Container>
  )
}

export default PollSaved