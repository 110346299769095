import axios from 'axios'

export const SET_COURSES = 'courses/SET_COURSES'
export const COURSES_START_FETCHING = 'courses/COURSES_START_FETCHING'
export const COURSES_STOP_FETCHING = 'courses/COURSES_STOP_FETCHING'

const setCoursesAreFetching = () => ({ type: COURSES_START_FETCHING })
const unsetCoursesAreFetching = () => ({ type: COURSES_STOP_FETCHING })
const setCourses = (coursesData) => ({
  type: SET_COURSES,
  payload: { coursesData },
})

export const getCoursesData = () => (dispatch, getState) => {
  const courseUid = getState().poll.pollData.course

  if (!courseUid) {
    dispatch(setCourses([]))
    return
  }

  dispatch(setCoursesAreFetching())
  return axios.get(`${window._env_.REACT_APP_API_URL}/actions/courses/${courseUid}?populate[0]=city&&populate[1]=kind`)
    .then(({ data }) => dispatch(setCourses([{
      courseUid: data._id,
      ...data,
    }])))
    .finally(() => dispatch(unsetCoursesAreFetching()))
}

const initialState = {
  coursesData: null,
  coursesAreFetching: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case COURSES_START_FETCHING:
      return {
        ...state,
        coursesAreFetching: true,
      }
    case COURSES_STOP_FETCHING:
      return {
        ...state,
        coursesAreFetching: false,
      }
    case SET_COURSES:
      return {
        ...state,
        coursesData: action.payload.coursesData,
      }
    default:
      return state
  }
}