import React from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'

import { Provider } from 'react-redux'
import { store } from './store'

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import App from './App'

import theme from './theme'
import './index.css'

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={createMuiTheme(theme)}>
      <App />
    </ThemeProvider>
  </Provider>
  ,
  document.getElementById('root')
)

serviceWorker.unregister()