import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Typography } from '@material-ui/core'

const style = {
  external: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: '#fff',
    zIndex: 9999,
  },
  internal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
}

const LoadingIndicator = ({ message }) => (
  <div style={style.external} >
    <div style={style.internal} >
      <CircularProgress size={80} />
      <br />
      <br />
      <Typography>{message}</Typography>
    </div>
  </div>
)

export default LoadingIndicator