import React from 'react'

import Container from '../../../components/Container'
import Button from '@material-ui/core/Button'

import t from '../../../translations'
import { Typography } from '@material-ui/core'

const OkButton = (props) => (
  <Container>
    <Typography
      paragraph={true}
      variant={'body1'}
    >
      {t.pollView.okBtnCaption}
    </Typography>
    <Button
      variant={'contained'}
      color={'primary'}
      onClick={props.onClick}
      fullWidth={true}
    >
      {t.pollView.okBtn}
    </Button>
  </Container>
)

export default OkButton