import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import {
  reducer as flash,
  middleware as flashMiddleware,
} from 'redux-flash'

import poll from './state/poll'
import saveAnswers from './state/saveAnswers'
import trainer from './state/trainer'
import courses from './state/courses'

const reducer = combineReducers({
  poll,
  saveAnswers,
  trainer,
  courses,
  flash,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
  reducer,
  composeEnhancers(
    applyMiddleware(
      flashMiddleware({ timeout: 5 * 1000 }),
      thunk,
    )
  )
)