import React from 'react'
import Paper from '@material-ui/core/Paper'

import { useTheme } from '@material-ui/core/styles'

const style = {
  margin: '1.2rem',
  padding: '1.5rem',
}

const Container = ({ children, zDepth, colored, printOnly, printHidden }) => {
  const theme = useTheme()

  const coloredStyle = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  }

  return (
    <div
      className={`Container ${(printOnly ? 'print-only ' : '')} ${(printHidden ? 'print-hidden ' : '')}`}
      style={{ margin: '0 auto', maxWidth: 1112 }}
    >
      <Paper
        className={'Container-Paper'}
        style={
          colored ?
            { ...style, ...coloredStyle }
            :
            style
        }
      >
        {children}
      </Paper>
    </div>
  )
}

export default Container