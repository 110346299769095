import React from 'react'

import { connect } from 'react-redux'
import { getPollData } from '../../state/poll'
import { saveAnswers, setAnswer, okButton } from '../../state/saveAnswers'

import { pollTemplatesIdsForOkButton } from '../../options'

import * as PollMessages from './PollMessages'
import Poll from './Poll'

class PollView extends React.Component {
  componentDidMount() {
    const pollUid = window.location.pathname.replace('/', '')

    this.props.getPollData(pollUid)
  }

  render() {
    const courseObj = (
      this.props.coursesData &&
      this.props.pollData &&
      this.props.coursesData.find(
        course => course.courseUid === this.props.pollData.course
      )
    )

    const displayOkButton = (
      this.props.pollData &&
      this.props.pollData.template &&
      pollTemplatesIdsForOkButton.includes(this.props.pollData.template._id)
    )

    return (
      this.props.pollIsAlreadyFilled ?
        <PollMessages.PollIsAlreadyFilled />
        :
        (this.props.pollIsFetching || this.props.trainersAreFetching || this.props.coursesAreFetching) ?
          <PollMessages.FetchingPoll />
          :
          !this.props.pollData ?
            <PollMessages.PollNotFound />
            :
            this.props.answersAreSaving ?
              <PollMessages.SavingPoll />
              :
              this.props.answersAreSaved ?
                <PollMessages.PollSaved pollData={this.props.pollData} />
                :
                <Poll
                  getCoursesData={this.props.getCoursesData}
                  saveAnswers={this.props.saveAnswers}
                  trainersData={this.props.trainersData}
                  courseObj={courseObj}
                  displayOkButton={displayOkButton}
                  setAnswer={this.props.setAnswer}
                  okButton={this.props.okButton}
                  answers={this.props.answers}
                  {...this.props.pollData}
                />
    )
  }
}

const mapStateToProps = (state) => ({
  pollIsFetching: state.poll.pollIsFetching,
  pollData: state.poll.pollData,
  pollIsAlreadyFilled: state.poll.pollIsAlreadyFilled,
  trainersAreFetching: state.trainer.trainersAreFetching,
  trainersData: state.trainer.trainersData,
  coursesData: state.courses.coursesData,
  coursesAreFetching: state.courses.coursesAreFetching,
  answersAreSaving: state.saveAnswers.answersAreSaving,
  answersAreSaved: state.saveAnswers.answersAreSaved,
  answers: state.saveAnswers.answers,
})

const mapDispatchToProps = (dispatch) => ({
  getPollData: (organizationUid, pollUid) => dispatch(getPollData(organizationUid, pollUid)),
  saveAnswers: (organizationUid) => dispatch(saveAnswers(organizationUid)),
  okButton: (organizationUid) => dispatch(okButton(organizationUid)),
  setAnswer: (questionId, answer) => dispatch(setAnswer(questionId, answer)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PollView)