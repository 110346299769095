export const mapObjectToArray = (obj) => (
  Object.entries(obj || {})
    .map(([key, value]) => (
      typeof value === 'object' && !(value instanceof Array) ?
        { ...value, key }
        :
        { key, value }
    ))
)

export const getNestedPropertyValue = (object, keyPath) => keyPath.reduce(
  (reduced, element, i, arr) => (
    reduced && reduced[element]
  ),
  object
)

export const reduceToCommonValues = (array, ...commonKeyPath) => (
  array.reduce(
    (reduced, element, i, arr) => {
      const commonValue = getNestedPropertyValue(element, commonKeyPath)
      
      if (reduced[commonValue])
        reduced[commonValue] = reduced[commonValue].concat(element)
      else
        reduced[commonValue] = [element]
      return reduced
    },
    {}
  )
)