import React from 'react'

import Container from '../../../components/Container'
import LoadingIndicator from '../../../components/LoadingIndicator'
import t from '../../../translations'

const SavingPoll = () => (
  <Container>
    <LoadingIndicator message={t.pollView.savingPoll} />
  </Container>
)

export default SavingPoll
