import React from 'react'

import DisplayFlashToasts from '@bit/amazingdesign.react-redux-mui-starter.display-flash-toasts';

import MainPage from './pages'

const App = () => (
  <>
    <MainPage />
    <DisplayFlashToasts bottom={'2rem'} right={'2rem'} />
  </>
)

export default App
