import moment from 'moment'

export const rangeQuestionOptions = ['1', '2', '3', '4', '5', 'not-present']
export const npsQuestionOptions = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']

export const pollIsAlreadyFilledLocalStoragePrefix = 'iSA-surveys-'

export const momentDefaultDateFormat = time => moment(time).format('DD/MM/YYYY')

export const requireTextAnswersIfMarkIsLowerThanX = 4
export const pollTemplatesIdsForOkButton = ['5e01f138dee919717c9f6321']