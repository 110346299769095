import React from 'react'
import Grid from '@material-ui/core/Grid'

import Container from '../Container'

import iSALogo from '../../images/iSALogoWhite.png'

const styles = {
  logoCol: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerCol: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}

const ColXs12Sm6 = (props) => (
  <Grid
    item={true}
    xs={12}
    sm={6}
    style={styles.headerCol}
    className={'force-for-print--col-sm-6'}
  >
    {props.children}
  </Grid>
)

const LogoContainer = (props) => (
  <div
    className={'LogoContainer'}
  >
    <Container {...props}>
      <Grid container={true}>
        <ColXs12Sm6>
          {props.children}
        </ColXs12Sm6>
        <ColXs12Sm6>
          <img
            style={{ maxHeight: '5em', maxWidth: '100%', padding: '0 0.5rem', boxSizing: 'border-box' }}
            src={iSALogo}
            alt="iSA Logo"
          />
        </ColXs12Sm6>
      </Grid>
    </Container>
  </div>
)

export default LogoContainer