import axios from 'axios'

import { flashMessage, flashErrorMessage } from 'redux-flash'

import {
  pollIsAlreadyFilledLocalStoragePrefix,
  requireTextAnswersIfMarkIsLowerThanX as requireTextAnswersIfMarkIsLowerThanXFromOptions,
} from '../options'

import t from '../translations'

const SET_POLL_ANSWER = 'answers/SET_POLL_ANSWER'
const SAVE_POLL_ANSWERS_START = 'answers/SAVE_POLL_ANSWERS_START'
const SAVE_POLL_ANSWERS_STOP = 'answers/SAVE_POLL_ANSWERS_STOP'
const SAVED_POLL_ANSWERS_CORRECTLY = 'answers/SAVED_POLL_ANSWERS_CORRECTLY'
const SAVED_POLL_ANSWERS_WITH_ERRORS = 'answers/SAVED_POLL_ANSWERS_WITH_ERRORS'

export const okButton = () => (dispatch, getState) => {
  const pollData = getState().poll.pollData
  pollData.questions.forEach(question => {
    let answer = null
    switch (question.type) {
      case 'RANGE':
        answer = 5
        break
      case 'NPS':
        answer = 10
        break
      case 'TEXT':
        answer = t.pollView.okBtnTxtAnswer
        break
      default:
        break
    }

    dispatch(setAnswer(question.questionId, answer, null))
  })

  dispatch(saveAnswers())
}

export const setAnswer = (questionId, answer, trainerUid) => ({
  type: SET_POLL_ANSWER,
  payload: { questionId, answer, trainerUid },
})

export const saveAnswers = () => (dispatch, getState) => {
  const requireTextAnswersIfMarkIsLowerThanX = Number(
    localStorage.getItem('__requireTextAnswersIfMarkIsLowerThanX__') ||
    requireTextAnswersIfMarkIsLowerThanXFromOptions
  )

  const pollData = getState().poll.pollData
  const pollUid = pollData.pollUid
  const currentAnswers = getState().saveAnswers.answers
  const trainersData = getState().trainer.trainersData
  const timestamp = Date.now()
  const answerSetUid = `${pollUid}-${timestamp}-${Math.random().toString(36).substr(2, 9)}`

  const requiredRangeQuestions = (
    pollData.questions.filter(question => question.type === 'RANGE' || question.type === 'NPS')
  )
  const numberOfUnansweredRequiredRangeQuestions = requiredRangeQuestions
    .filter(question => currentAnswers[question.questionId] === undefined)
    .length

  if (numberOfUnansweredRequiredRangeQuestions > 0) {
    dispatch(flashMessage(t.pollView.fillRequired))
    return
  }

  const trainersWithLowMark = pollData.questions
    .filter(question => question.type === 'RANGE')
    .filter(question => (
      currentAnswers[question.questionId]) !== 'not-present' &&
      Number(currentAnswers[question.questionId]) < requireTextAnswersIfMarkIsLowerThanX
    )
    .map(question => question.trainer)

  const trainersWithLowMarkAndEmptyTextQuestions = trainersWithLowMark
    .filter(trainerId => {
      const lowMarkTrainerTextQuestions = pollData.questions.filter(question => (
        question.trainer === trainerId &&
        question.type === 'TEXT'
      ))

      if (lowMarkTrainerTextQuestions.length === 0) {
        // cant have answers for text questions if there no text questions
        return false
      }

      const lowMarkTrainerFilledTextQuestions = lowMarkTrainerTextQuestions.filter(question => (
        currentAnswers[question.questionId] !== undefined &&
        currentAnswers[question.questionId].trim() !== ''
      ))

      // minimum one TEXT question has to be filled
      return lowMarkTrainerFilledTextQuestions.length === 0
    })

  if (trainersWithLowMarkAndEmptyTextQuestions.length > 0) {
    const trainersNames = trainersWithLowMarkAndEmptyTextQuestions.map(trainerId => {
      const trainer = trainersData.find(trainer => trainer.trainerUid === trainerId)

      if (!trainer) return trainerId

      return `- ${trainer.firstName} ${trainer.lastName}`
    })

    dispatch(
      flashMessage(`
      ${t.pollView.fillRequiredReasons}
      ${trainersNames.join('\n')}
    `
        .replace(/^ {6}/gm, '').replace(/^ {4}/gm, ''))
    )
    return
  }

  dispatch({ type: SAVE_POLL_ANSWERS_START })

  Promise.all(
    Object.entries(currentAnswers)
      .map((entry) => {
        const questionUid = entry[0]
        const answer = entry[1]
        const question = pollData.questions.filter(question => question.questionId === questionUid)[0]
        return axios.post(
          `${window._env_.REACT_APP_API_URL}/actions/answers`,
          {
            questionUid,
            answer,
            timestamp,
            answerSetUid,
            pollUid,
            trainerUid: question.trainer || null,
            owner: question.owner || null,
            questionType: question.type,
          }
        )
      }),
  )
    .then((data) => {
      window.localStorage.setItem(`${pollIsAlreadyFilledLocalStoragePrefix}${pollUid}`, Date.now())
      dispatch({ type: SAVE_POLL_ANSWERS_STOP })
      dispatch({ type: SAVED_POLL_ANSWERS_CORRECTLY })
    }).catch(error => {
      dispatch({ type: SAVE_POLL_ANSWERS_STOP })
      dispatch({ type: SAVED_POLL_ANSWERS_WITH_ERRORS, payload: { error } })
      dispatch(flashErrorMessage(t.pollView.error))
    })
}

const initialState = {
  answers: {},
  answersAreSaving: false,
  answersAreSaved: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_POLL_ANSWERS_START:
      return {
        ...state,
        answersAreSaving: true,
      }
    case SAVE_POLL_ANSWERS_STOP:
      return {
        ...state,
        answersAreSaving: false,
      }
    case SAVED_POLL_ANSWERS_CORRECTLY:
      return {
        ...state,
        answersAreSaved: true,
      }
    case SET_POLL_ANSWER:
      let newAnswer = {}
      newAnswer[action.payload.questionId] = action.payload.answer
      return {
        ...state,
        answers: {
          ...state.answers,
          ...newAnswer,
        },
      }
    default:
      return state
  }
}