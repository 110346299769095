import React from 'react'

import { momentDefaultDateFormat } from '../../options'
import { Typography } from '@material-ui/core'

export const displayText = course => (
  `${course.kind && course.kind.name} [ ${course.prefix} ] ${course.name} ${course.city && course.city.name}  ` +
  (
    course.fromDate && course.fromDate ?
      ` ${momentDefaultDateFormat(course.fromDate)} - ${momentDefaultDateFormat(course.toDate)}`
      :
      ''
  )
)

const displayHTML = course => (
  <Typography paragraph={true} variant={'body1'} style={{ fontWeight: 700 }}>
    {course.kind && course.kind.name}
    <br />
    [ {course.prefix} ] {course.name}
    <br />
    {course.city && course.city.name}
    {
      course.fromDate && course.fromDate ?
        <Typography variant={'body1'} component={'span'} style={{ fontWeight: 700 }}>
          {' '} {momentDefaultDateFormat(course.fromDate)} - {momentDefaultDateFormat(course.toDate)}
        </Typography>
        :
        null
    }
  </Typography>
)

const CourseDisplayName = (props) => (
  props.displayAsText ?
    displayText(props.course)
    :
    displayHTML(props.course)
)

export default CourseDisplayName