import React from 'react'

import LogoContainer from '../../../components/LogoContainer'
import CourseDisplayName from '../../../components/CourseDisplayName'
import QuestionsPerTrainerAndSubject from './QuestionsPerTrainerAndSubject'
import SavePollButton from './SavePollButton'
import OkButton from './OkButton'

import { reduceToCommonValues, mapObjectToArray } from '../../../utils'
import { Typography } from '@material-ui/core'

const Poll = (props) => {
  return (
    <div>
      <LogoContainer
        colored={true}
      >
        <Typography paragraph={true} variant={'h4'} component={'h1'} style={{ fontWeight: 700 }}>
          {props.name}
        </Typography>
        <CourseDisplayName
          course={props.courseObj}
        />
      </LogoContainer>
      {
        <div>
          {
            props.displayOkButton ?
              <OkButton
                onClick={() => props.okButton()}
              />
              :
              null
          }
          {
            props.questions && mapObjectToArray(
              reduceToCommonValues(props.questions, 'trainer')
            ).map(questionsPerTrainer => {
              const trainerUid = questionsPerTrainer.key
              const questions = questionsPerTrainer.value

              return mapObjectToArray(
                reduceToCommonValues(questions, 'subject')
              ).map(questionsPerSubject => {
                const subject = questionsPerSubject.key
                const questions = questionsPerSubject.value

                const trainer = trainerUid && props.trainersData.find(trainer => trainer.trainerUid === trainerUid)

                return (
                  <QuestionsPerTrainerAndSubject
                    key={trainerUid}
                    trainer={trainer}
                    subject={subject}
                    questions={questions}
                    setAnswer={props.setAnswer}
                    answers={props.answers}
                  />
                )
              })
            })
          }
          <SavePollButton
            onClick={() => props.saveAnswers()}
          />
        </div>
      }
    </div>
  )
}

export default Poll