import React from 'react'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { rangeQuestionOptions as options } from '../../../../options'
import t from '../../../../translations'
import './QuestionRange.css'
import { Typography } from '@material-ui/core'

const QuestionRange = ({ question, setAnswer, answers }) => {
  const pickedAnswer = answers && answers[question.questionId]

  const pickAnswerHandler = (event) => setAnswer(question.questionId, event.target.value)

  return (
    <div className={'QuestionRange'}>
      <br />
      <Typography variant={'h6'} style={{ fontWeight: 700 }}>{question.caption}</Typography>
      <Typography paragraph={true} variant={'body1'}>{question.description}</Typography>
      <FormControl component={'fieldset'}>
        <RadioGroup
          style={{
            justifyContent: 'center',
            textAlign: 'center',
          }}
          row={true}
          onChange={pickAnswerHandler}
          name={question.questionId}
          value={pickedAnswer}
        >
          {options.map((option, index, arr) => (
            <FormControlLabel
              key={index}
              className={'range-question__radio-button'}
              value={option}
              control={<Radio color={'primary'} />}
              label={option === 'not-present' ? t.pollView.notPresent : option}
              labelPlacement={'bottom'}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <br />
    </div>
  )
}

export default QuestionRange