import React from 'react'

import { Divider } from '@material-ui/core'
import QuestionRange from './QuestionRange'
import QuestionNps from './QuestionNps'
import QuestionText from './QuestionText'

import { useTheme } from '@material-ui/core/styles'

const Question = (props) => {
  const theme = useTheme()

  const styles = {
    trainerHeader: {
      textAlign: 'center',
    },
    divider: {
      backgroundColor: theme.palette.primary.main,
    },
  }

  let QuestionComponent = null

  switch (props.question.type) {
    case 'RANGE':
      QuestionComponent = QuestionRange
      break
    case 'NPS':
      QuestionComponent = QuestionNps
      break
    case 'TEXT':
      QuestionComponent = QuestionText
      break
    default:
      return null
  }

  return (
    <div>
      <QuestionComponent
        question={props.question}
        setAnswer={props.setAnswer}
        answers={props.answers}
      />
      {
        props.isNotLastQuestion ?
          <div>
            <br /><br />
            <Divider style={styles.divider} />
          </div>
          :
          null
      }
    </div>
  )
}

export default Question